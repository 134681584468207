import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Page from "../page/page"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import GuildData from './guild_data'
import {withStyles} from "@material-ui/core"

const styleGuild = theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  leader: {
    marginTop: -5,
    marginBottom: theme.spacing(1)
  }
});

const Guild = props => {
  const { guild, classes } = props;

  return (
    <Grid container direction={'column'} className={classes.root}>
      <Grid container direction={'row'}>
        <Typography variant={'h6'}>{guild.name}</Typography>
      </Grid>
      <Typography variant={'body2'} className={classes.leader}>Leader: {guild.leader}</Typography>
      <Typography variant={'body1'}>{guild.description}</Typography>
    </Grid>
  )
}

const StyledGuild = withStyles(styleGuild)(Guild);

class Guilds extends Component {
  render() {
    return (
      <Page>
        <Typography variant={'h3'}>Guilds</Typography>
        <Typography variant={'body1'}>
          Guilds are run by the players. Each guild is owned by a player through
          purchase from an immortal. That player is in full control of the guild, adding
          new players, removing them, and purchasing new guild powers. A player may use
          any criteria for adding and removing guild members, although each guild does
          have restrictions which define membership.
        </Typography>

        <Divider />

        <Grid container>
          {Object.keys(GuildData).map(k => <StyledGuild guild={GuildData[k]} />)}
        </Grid>
      </Page>
    );
  }
}

Guilds.propTypes = {};

export default Guilds;