import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router"
import ClassData from "./class_data"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import NotFound from "../../errors/404"
import {withStyles} from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import ClassList from "./class-list"
import SubclassList from "./subclass-list"

const style = theme => ({
  root: {
    '& h6': {
      marginTop: theme.spacing(2),
    }
  },
  stats: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  raceList: {
    marginTop: theme.spacing(2),
    '& hr': {
      marginBottom: theme.spacing(2),
    }
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
});

class ViewSubclass extends Component {
  render() {
    const { match, classes } = this.props;
    const subclassName = match.params.subclassName;
    const cls = match.params.className;

    if (!ClassData[cls] || !ClassData[cls].subclasses[subclassName]) {
      return <NotFound />
    }

    const subclass = ClassData[cls].subclasses[subclassName];

    return (
      <Grid container className={classes.root} direction={'column'}>
        <Typography variant={'h3'}>{subclass.name}</Typography>
        <Typography variant={'h6'}>Description</Typography>
        <Typography variant={"body1"}>{subclass.description}</Typography>
        <Typography variant={'h6'}>Location</Typography>
        <Typography variant={"body1"}>{subclass.location}</Typography>
        <Typography variant={'h6'}>Other Subclasses</Typography>
        <SubclassList cls={cls}/>
        <Grid item sm={12} className={classes.raceList}>
          <Divider />
          <ClassList />
        </Grid>
      </Grid>
    );
  }
}

ViewSubclass.propTypes = {};

ViewSubclass = withRouter(ViewSubclass);

ViewSubclass = withStyles(style)(ViewSubclass);


export default ViewSubclass;