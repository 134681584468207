import React from "react"

const races = {
  'angel': {
    name: 'Archangel',
    description: <div>An archangel is a sight from heaven that few people are fortunate enough to
      see themselves. They appear as beautiful humanoids of nearly opaque skin
      color and form. They have large feathery wings and incredibly toned
      physiques. Hair and eyes vary greatly among each angel and all are nearly
      perfect in form.</div>,
    characteristics: <div>
      The archangel of Primal Darkness is the embodiment of hope and all which is
      holy. Courageous, honorable and kind, archangels protect the innocent and
      defend the realm from darkness. They valiantly charge into battle against
      their eternal rivals for the sake of all existence.
    </div>,
    strength: 9,
    constitution: 9,
    dexterity: 11,
    charisma: 14,
    intelligence: 11,
    wisdom: 13,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 4,
    questRace: true,
    language: 'Annarian',
  },
  'bastet': {
    name: 'Bastet',
    description: <div>Bastets are a cast of proud, elegant man-cat hybrids. Their fur ranges from
      deep black to shades of brown, red, white, grey and gold. Often they will
      have longer hair in some parts of their bodies than in others, usually
      around the head and tail, resulting in a sleek or ferocious, lion-like
      appearance.</div>,
    characteristics: <div>Bastets are typically very proud, regal people. Expressing both a playful
      personality and a fierce passion for the hunt. It is fairly uncommon to see
      a lone bastet, by nature they are very social and enjoy company.
    </div>,
    strength: 8,
    constitution: 9,
    dexterity: 12,
    charisma: 12,
    intelligence: 7,
    wisdom: 6,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 5,
    questRace: false,
    language: 'Radynian',
  },
  'centaur': {
    name: 'Centaur',
    description: <div>Centaurs are an ancient race of people with the upper bodies of a human
      male or female and the lower bodies of a horse. They stand quite tall,
      often over two meters and usually are quite awkward and displeasing to look
      at. They are durable and powerful, but not known as scholars.</div>,
    characteristics: <div>The centaurs of Primal Darkness are usually peaceful and quite friendly to
      strangers. They are usually aligned with the forces of light and fight for
      honor and justice. Evil centaurs are often hunted by those of their own
      kind.
    </div>,
    strength: 11,
    constitution: 12,
    dexterity: 7,
    charisma: 7,
    intelligence: 6,
    wisdom: 7,
    arms: 2,
    legs: 4,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: false,
    language: 'Nhulan',
  },
  'demon': {
    name: 'Demon',
    description: <div>Nether demons stand nearly 2.5 meters tall with extremely powerful builds.
      They have sleek obsidian skin that feels of wet leather and lacks any
      noticeable hair. Nether demons have souless black eyes that are devoid of
      any features, large protruding horns at the temples, and large batlike
      wings.</div>,
    characteristics: <div>The nether demon of Primal Darkness is a dangerous nemesis to all types of
      life. Feeding on the suffering of others, they thrive on carnage and terror
      while seeking to gain favor of their lord Asmodeus. They are cunning and
      powerful but tend to fall victim to their own haphazardness.
    </div>,
    strength: 15,
    constitution: 13,
    dexterity: 12,
    charisma: 4,
    intelligence: 9,
    wisdom: 9,
    arms: 2,
    legs: 5,
    fingersPerHand: 5,
    nightVision: 6,
    questRace: true,
    language: 'Kanashi',
  },
  'dragon': {
    name: 'Dragon',
    description: <div>Dragons are known to be both strong and sturdy as well as surprisingly
      intelligent. Their lizard descent lends them very keen eyesight in dark
      places. Although dragons have four legs, they can easily stand on their
      rear legs, using their forelegs as arms. Dragons have large wings that can
      be used to fly great distances, with the exception of the Blue Dragon, who
      has much smaller wings and a bigger body. Some dragons are stronger,
      faster, smarter, or wiser than others. </div>,
    characteristics: <div>Very rarely seen, it is a mystery where the dragonkin originated from. Not
      seen until late in the world of Primal Darkness, little is known of the
      dragon way of life. There have been reports of Red, Black, White and Blue
      dragons. Although quite aloof, the white dragon is the most loved because
      of its saintly nature. The black dragon has developed a name for itself by
      cruelly destroying towns and cities with it's dark magicks. Red dragons
      care nothing for magic, but are feared all the more for their fierce battle
      tactics. Not much is known of the blue dragon, as they are the most rare
      form of dragon. They are usually only seen from great distance, their tiny
      wings trying to carry the weight of the gigantic dragon through the air. </div>,
    strength: 9,
    constitution: 17,
    dexterity: 9,
    charisma: 8,
    intelligence: 9,
    wisdom: 9,
    arms: 0,
    legs: 2,
    fingersPerHand: 3,
    nightVision: 6,
    questRace: true,
    language: 'Dragonian',
  },
  'drow': {
    name: 'Drow',
    description: <div>Drows are dark elves, banished long ago by their cousins. Their skin ranges
      from light purple to black, with white hair and light eyes. They are
      slightly swifter of body and mind than surface elves, but also more frail.
      Most drows are considered attractive. Their personalities tend to
      overshadow their physical looks, however.</div>,
    characteristics: <div>The dark elves of Primal Darkness have just recently reappeared from their
      banishment. Largely of one mind, they seek vengeance upon the elves who
      banished them. Drow society is highly religious and hierarchial. Most drows
      are concerned with personal power, whether martial, magical, or political,
      and they will not hesitate to do whatever it takes to achieve their goals.</div>,
    strength: 7,
    constitution: 7,
    dexterity: 11,
    charisma: 7,
    intelligence: 11,
    wisdom: 11,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 6,
    questRace: false,
    language: 'Ilythiiri',
  },
  'dwarf': {
    name: 'Dwarf',
    description: <div>Stout yet powerful, dwarves pack an incredible punch for their size. They
      are extremely well built and stand nearly 1.5 meters tall. Both the males
      and females grow long beards, which they display proudly. Their skin is
      tough and tends to stay in shades of brown or grey.</div>,
    characteristics: <div>The dwarves of Primal Darkness are a strong and hearty folk fond of strong
      drink, fighting and most of all, gold. They are extremely dedicated and
      hard working and tend to make a solid living as  soldiers, mercenaries or
      craftsman--all of which they commend top price for. </div>,
    strength: 11,
    constitution: 11,
    dexterity: 8,
    charisma: 8,
    intelligence: 6,
    wisdom: 9,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 5,
    questRace: false,
    language: 'Dimidium',
  },
  'elf': {
    name: 'Elf',
    description: <div>Elves are slender humanoids with pointed ears and slightly elongated
      features. Skin tones vary greatly, from pale white to black as well as blue
      and green and their eyes tend to be as diverse as well. Their exotic looks
      are found quite attractive by most races. Elves are nimble and usually very
      intelligent.</div>,
    characteristics: <div>The elves of Primal Darkness are nearly as diverse as humans. They tend to
      pick up any number of professions and excel in those that require higher
      thought or good coordination. Elves are usually respected by most other
      people despite their often arrogant disposition.</div>,
    strength: 8,
    constitution: 8,
    dexterity: 9,
    charisma: 10,
    intelligence: 11,
    wisdom: 11,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: false,
    language: 'Pulchra',
  },
  'faerie': {
    name: 'Faerie',
    description: <div>Faeries are a delicate people of only inches in height. They are frail but
      exceptionally swift. Most races find faeries quite beautiful and few would
      question that they are among the most knowledgeable of races. Faerie skin
      tones and eye colors tend to follow no particular pattern. However all show
      vibrant color in their wings and bear complex spotting upon the skin.</div>,
    characteristics: <div>Faeries by nature are a playful people. They often find causing trouble or
      picking a pocket all in 'good spirit'. Few ever find the dedication to
      develop their talents outside of gaming and play, but more and more have
      shown a deep dedication to training and study in preparation of the dark
      days ahead. </div>,
    strength: 2,
    constitution: 2,
    dexterity: 11,
    charisma: 9,
    intelligence: 12,
    wisdom: 11,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 1,
    questRace: false,
    language: 'Vertigan',
  },
  'ferrel': {
    name: 'Ferrel',
    description: <div>Ferrels stand nearly 2 meters in height when upright but tend to hunch
      rather heavily. Humans would say they appear as a cross between rodent and
      man but that is far from exact. Their faces however bear a striking
      resemblance to the common field mouse and long furless tails do nothing to
      dismiss the comparisons.</div>,
    characteristics: <div>The ferrels of Primal Darkness are as inquisitive as they are agile to
      which few if any are their equals. Ferrels are by nature extremely
      self-serving and most make a living as a con artist or petty thief. The
      great ones however are among the best jesters, bards, and assassins in the
      realm.</div>,
    strength: 7,
    constitution: 8,
    dexterity: 17,
    charisma: 7,
    intelligence: 11,
    wisdom: 7,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 6,
    questRace: false,
    language: 'Nysaali',
  },
  'giant': {
    name: 'Giant',
    description: <div>Giants are wide and varied but all giants are enormous in size and strength
      and incredibly dimwitted. They have varying colors of hair, skin and eyes
      and are as often beautiful as they are ugly.</div>,
    characteristics: <div>The giants of Primal Darkness tend to vary greatly. The stone giants
      recluse in mountainous areas where they savagely protect their homes. The
      hill giants live among the hills and among people, often using their great
      strength and size to help those physically weaker. And then there is the
      bold adventurer whose disposition and behavior is always a mystery.</div>,
    strength: 17,
    constitution: 15,
    dexterity: 5,
    charisma: 6,
    intelligence: 6,
    wisdom: 3,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 2,
    questRace: false,
    language: 'Emiolios',
  },
  'gnoll': {
    name: 'Gnoll',
    description: <div>Gnolls are tall and slender humanoids that appear as a cross of a hyena and
      man. Most are near 2 meters tall and have thick, short, spotted fur that
      covers their entire body. Gnoll eye colors are usually red or black.</div>,
    characteristics: <div>The gnolls of Primal Darkness have a long and dark history that began on
      the island of Merkkirri. Most have been corrupted by evil and are content
      with a life of plunder and dark worship. However, many have ventured beyond
      their jungle confines in search of a better life.</div>,
    strength: 11,
    constitution: 10,
    dexterity: 10,
    charisma: 6,
    intelligence: 9,
    wisdom: 7,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 4,
    questRace: false,
    language: 'Merkish',
  },
  'gnome': {
    name: 'Gnome',
    description: <div>Gnomes, characterized by their large ears and pointy noses, are short
      averaging 1.5 meters and of medium to slight build. Their skin ranges from
      grey to green and even violet and most grow thick white beards which they
      proudly display. They are extremely wise but suffer from frail bones.</div>,
    characteristics: <div>The gnomes of Primal Darkness are a very scholarly race that thrive on the
      aquisition of knowledge. They often pursue magical interests or set into a
      life of invention where they are among the greatest minds known. Gnomes are
      in general a peaceful people but not uncommon are the ones who found the
      temptation of dark magic too strong to resist.</div>,
    strength: 6,
    constitution: 7,
    dexterity: 7,
    charisma: 8,
    intelligence: 11,
    wisdom: 14,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 5,
    questRace: false,
    language: 'Giarrok',
  },
  'goblin': {
    name: 'Goblin',
    description: <div>Goblins are short creatures of about 1.5 meters with grey, green or black
      skin, usually warted and smelling of various foul odors. They most often
      have green eyes and black pupils. Goblins are quick and fairly strong. They
      are rarely attractive and most are born idiots.</div>,
    characteristics: <div>The goblins of Primal Darkness were once a mighty empire ruled by several
      warlords and a mighty emperor. However civil war among the warlords caused
      the empire to collapse and now the goblins usually form clans of differing
      but usually nefarious goals. Goblins are usually very untrustworthy, but
      their constant infighting has resulted in them developing thicker skins and
      harder heads that protect against some kinds of damage.</div>,
    strength: 10,
    constitution: 8,
    dexterity: 11,
    charisma: 4,
    intelligence: 6,
    wisdom: 5,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 5,
    questRace: false,
    language: 'Volato',
  },
  'gremlin': {
    name: 'Gremlin',
    description: <div>Gremlins are hideously ugly denizens of small size but incredible strength.
      They rarely stand over a meter in height and have incredible natural
      agility. Gremlin skin is always green and usually marked by streaks or
      patches of hair. They have glowing yellow eyes and two separate rows of
      teeth. They are not known for being bright but have a high mental affinity
      and thus are quite familiar with magic.</div>,
    characteristics: <div>The gremlins of Primal Darkness are the result of a magical spell or mishap
      that brought the creature from the nightmare dimension. They are insanely
      wicked and have little concept of remorse or even 'looking back'. Most
      stalk the world in search of trouble and await the day Asmodeus wins the
      war.</div>,
    strength: 10,
    constitution: 6,
    dexterity: 11,
    charisma: 6,
    intelligence: 7,
    wisdom: 11,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 4,
    questRace: false,
    language: 'Diabolis',
  },
  'halfelf': {
    name: 'Half-elf',
    description: <div>Half-elves are just what their name implies. They are the offspring of both
      an elf and human and hence have received both the penalties and advantages
      of each race. They appear as fully human except for they tend to be
      slightly smaller in build and many have pointed ears. Most half-elves are
      attractive.</div>,
    characteristics: <div>The half-elves of Primal Darkness are a race without a custom of their own.
      They are usually well liked and find homes within human cities, forests or
      rarely an elven populace. While they usually relate well to humans, elves
      tend to look at half-elves in the same manner one might view a 'mutt'.</div>,
    strength: 8,
    constitution: 8,
    dexterity: 9,
    charisma: 10,
    intelligence: 10,
    wisdom: 10,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: false,
    language: 'Pulchra',
  },
  'halfling': {
    name: 'Halfling',
    description: <div>Halflings are a short people of usually cute appearance. They have furry
      feet and hands and have a body portioned similar to a human baby. Most
      stand no more than a meter in height and have dark hair or rarely red or
      blonde, dark eyes and tan skin. They are extremely nimble and quite sturdy
      for their size.</div>,
    characteristics: <div>The halflings of Primal Darkness are a well loved and well respected people
      who often travel the realm in search of good adventure, good stories and
      good wine. Halflings tend to live bold lives and when they finally do
      choose to settle down, they do it for good.</div>,
    strength: 6,
    constitution: 8,
    dexterity: 12,
    charisma: 11,
    intelligence: 8,
    wisdom: 9,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 4,
    questRace: false,
    language: 'Selunian',
  },
  'human': {
    name: 'Human',
    description: <div>Humans are a well balanced race both mentally and physically. They stand
      near 2 meters and have a wide variety of hair colors, skin tones, and
      variations in eyes. Humans should never be underestimated as they have few
      penalties that prohibit their development.</div>,
    characteristics: <div>The humans of Primal Darkness are a war hungry race that always strives to
      better itself and expand. They are never satisfied and that insatiable
      thirst has driven them to progress faster than any previous race. Many
      ancient peoples such as elves and dwarves cautiously watch these creatures,
      as humans can be very unpredictable.</div>,
    strength: 9,
    constitution: 9,
    dexterity: 9,
    charisma: 9,
    intelligence: 9,
    wisdom: 9,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: false,
    language: 'Selunian',
  },
  'illithid': {
    name: 'Illithid',
    description: <div>Illithid are strange underground creatures with blue to violet skin
      standing nearly 2 meters tall. Their most distinguishing trait are the
      tentacles that prodrude near the mouth, used for feeding. Illithid are
      extremely intelligent and have very strong minds that help them cast
      magical and psionic attacks. Illithid have a tough yet extremely flexible
      skin that glistens with slime. Their eyes are white and without pupils.</div>,
    characteristics: <div>The illithid of Primal Darkness are for the most part, highly intelligent
      murderers who prey upon the brains of their victims. The challenge of
      defeating a specimen with higher intelligence makes the feast that much
      more worthwhile, therefore, the illithid tend to attack sentient beings.
      Not all illithid behave in this manner yet their reputation tends to
      suggest otherwise.</div>,
    strength: 6,
    constitution: 9,
    dexterity: 6,
    charisma: 4,
    intelligence: 15,
    wisdom: 10,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 6,
    questRace: false,
    language: 'Illthuu',
  },
  'imp': {
    name: 'Imp',
    description: <div>Imps are small demonic creatures that are extremely intelligent and quite
      ugly. They stand only half a meter in size and have a similar wingspan.
      Imps are usually red, green, blue or black in color with glistening black
      claws and teeth. Their eyes are usually a dark crimson with orange-yellow
      irises. Imps are quite swift.</div>,
    characteristics: <div>The imps of Primal Darkness are a race of despicable, foul-mouthed
      know-it-alls. The imps presence in the realms is usually the product of a
      weak spellcaster who was murdered after attempting to control the imp. Imps
      are extremely intelligent and have a hard time resisting any test of their
      knowledge.</div>,
    strength: 4,
    constitution: 4,
    dexterity: 10,
    charisma: 5,
    intelligence: 12,
    wisdom: 12,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 5,
    questRace: false,
    language: 'Illisan',
  },
  'lich': {
    name: 'Lich',
    description: <div>A lich appears as the remains of an undead human skeleton standing two
      meters in height. In place of eye sockets, two glowing fires burn with
      relentless anguish. How the lich 'lives' remains a mystery.</div>,
    characteristics: <div>The lich of Primal Darkness feeds upon the energies of the living. While
      liches are beyond the actual need of energy as sustinance, the life forces
      do strengthen the liches powers making them even more formidable. It is
      rumored the liches powers are so great that they are immune to the
      paralyzing prayers of clergy.</div>,
    strength: 5,
    constitution: 6,
    dexterity: 6,
    charisma: 1,
    intelligence: 17,
    wisdom: 17,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: false,
    language: 'Undeadish',
  },
  'minotaur': {
    name: 'Minotaur',
    description: <div>The minotaurs are a massive people hailing from the old Tauresian empire.
      They have the head of a bull and the bodies of a large human. The coats of
      the beast are usually brown, black or red in color and the skin tones are
      as varied as a humans. Minotaurs are extremely  powerful and durable
      although not exactly known for their agility.</div>,
    characteristics: <div>The minotaurs of Primal Darkness are a displaced people whos roots tie them
      to one of the greatest fallen empires in history. They roam the world
      seeking to find purpose in life all the while satisfying their overpowering
      drive to better themselves.</div>,
    strength: 14,
    constitution: 11,
    dexterity: 8,
    charisma: 5,
    intelligence: 5,
    wisdom: 5,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 2,
    questRace: false,
    language: 'Taurosian',
  },
  'netherman': {
    name: 'Netherman',
    description: <div>Netherman average just over 2 meters in height with large builds, jet-black
      hair and smooth olive green skin. Their facial features share more in
      common with their ogre ancestry, having sunken black eyes with white
      pupils and black teeth.</div>,
    characteristics: <div>The netherman of Primal Darkness are so-called due to myth that these
      people are the children of demon and man. In actuality, netherman are the
      strange experiment of long forgotten wizards involving the joining of man
      and ogre. They are fierce and powerful and known for having an extreme
      sense of honor.</div>,
    strength: 11,
    constitution: 13,
    dexterity: 7,
    charisma: 7,
    intelligence: 7,
    wisdom: 6,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 4,
    questRace: false,
    language: 'Lino',
  },
  'nymph': {
    name: 'Nymph',
    description: <div>Nymphs are the embodiment of beauty itself. Nymphs appear similar to female
      elves with extraordinary beauty and charm. They are fleet of foot and can
      be very clever. Nymphs skin tone and hair and eyes vary greatly but always
      fall in a combination pleasing to the eye.</div>,
    characteristics: <div>The nymphs of Primal Darkness search for simpler times. They despise war
      and suffering and wish only to live among their male followers which serve
      and protect the nymph. However, in this age, conflict is a fact of life and
      even the beautiful nymph must resort to crafty measures in order to
      survive.</div>,
    strength: 7,
    constitution: 7,
    dexterity: 13,
    charisma: 17,
    intelligence: 8,
    wisdom: 7,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 2,
    questRace: false,
    language: 'Attonitian',
  },
  'ogre': {
    name: 'Ogre',
    description: <div>Ogres are huge brutish beasts of incredible mass and stupidity. Ogres stand
      nearly 2.5 meters high and often weigh in excess of one thousand pounds.
      They are usually grey or green in color with coarse skin and small sunken
      eyes. Most ogres have long straight black hair which is usually oily and
      unwashed. Ogres are one of the strongest humaniod races known.</div>,
    characteristics: <div>The ogres of Primal Darkness are a dying race. Pushed by humans and their
      even more twisted cousins, the orcs, ogres have found themselves nearly
      wiped off the face of the world. Ogres tend to form strong bonds amongst
      each other and are very protective of their own kind. Most races look at
      ogres with open disgust for they aren't the brightest of people.</div>,
    strength: 12,
    constitution: 17,
    dexterity: 5,
    charisma: 4,
    intelligence: 5,
    wisdom: 3,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 4,
    questRace: false,
    language: 'Gantian',
  },
  'orc': {
    name: 'Orc',
    description: <div>Orcs are related to ogres and are nearly the same size but tend to be of
      slighter build and show more intelligence. Orcs are usually green to yellow
      in skin tone and are often quite hairy. Orcs have dark sunken eyes and
      coarse hair and always smell. Orcs are swifter than their larger cousins.</div>,
    characteristics: <div>The orcs of Primal Darkness are among the most savage and warlike people
      known. They thrive on aggression and have even gone so far as to attacking
      their own cousins the ogres. Orcs are rarely loyal to anything but their
      own personal glory. As with any race, this is not always the case.</div>,
    strength: 13,
    constitution: 11,
    dexterity: 8,
    charisma: 3,
    intelligence: 6,
    wisdom: 3,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 4,
    questRace: false,
    language: 'Turpis',
  },
  'puca': {
    name: 'Puca',
    description: <div>Pucas stand about a meter tall and tend to be covered in short fur. They
      have elongated ears similar to small animals and their faces are very
      animal-like as well, with sizeable noses and eyes. Most pucas have dark
      colorings and bright yellow or green eyes. They have long legs for their
      height and can run very quickly. All pucas have a long tail with which they
      can hold things, like a monkey can.</div>,
    characteristics: <div>A puca is a mischievious soul. They delight in confusing and sometimes
      scaring others whenever possible, but usually do not harm anyone. Pucas do
      not take well to organized government and law, so they usually wander free
      on their own, finding enjoyment in their adventures.</div>,
    strength: 5,
    constitution: 5,
    dexterity: 14,
    charisma: 14,
    intelligence: 12,
    wisdom: 5,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 4,
    questRace: false,
    language: 'Wilonis',
  },
  'raknid': {
    name: 'Raknid',
    description: <div>Raknids are terrifying to behold standing 2.5 meters or more in height,
      length, and width. They have a hardened exoskeleton with a small manticled
      mouth and glowing blue eyes. The most distinguishing characteristic of the
      raknid is its arms and legs, having four of each and using them much in the
      manner as an arachnid. Raknids are extremely quick and powerful.</div>,
    characteristics: <div>The raknids of Primal Darkness are solitary creatures by nature and quite
      intelligent. Most will never learn the ways of magic or civilized society,
      preferring to surrender to more primal instincts. However, a rare few of
      these creatures instead choose to explore the realm in search of new
      adventure  and new beginnings.</div>,
    strength: 7,
    constitution: 8,
    dexterity: 12,
    charisma: 2,
    intelligence: 2,
    wisdom: 5,
    arms: 4,
    legs: 4,
    fingersPerHand: 2,
    nightVision: 4,
    questRace: false,
    language: 'Fehler',
  },
  'satyr': {
    name: 'Satyr',
    description: <div>Satyrs have the upper torso, head, and arms of a man, and the lower body of
      a goat. Their legs are usually covered in brown or black fur. In place of
      feet, they have hooves. Adult satyrs have two small horns on their heads,
      which range from very short nubs to decorative curls. They are fairly fast
      and not of slight build, though they tend away from the scholarly arts.</div>,
    characteristics: <div>Most satyrs prefer to socialize with anyone they find friendly. Satyrs
      themselves are considered some of the most entertaining people around
      despite their odd appearance. They tend to indulge their passions whenever
      possible, which usually involves a large gathering and a lot of drinking.
      Those who seek adventure often do so in search of others with whom to
      carouse.</div>,
    strength: 9,
    constitution: 8,
    dexterity: 11,
    charisma: 12,
    intelligence: 8,
    wisdom: 6,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 3,
    questRace: false,
    language: 'Dionysian',
  },
  'sauran': {
    name: 'Sauran',
    description: <div>Saurans are extremely massive and powerfully built. Most stand over two
      meters tall and some even reach three meters. Their skin tones are green,
      red, black, blue and occassionally white or gold. Overall they appear as
      dragon-men with protruding horns and a medium length tail. They have
      remarkable movement for their size but are exceptionally  vunerable to
      magic due to their often low wisdom.</div>,
    characteristics: <div>The saurans of Primal Darkness are a stern race often with little humor.
      They tend to excel in professions of war and protection. While very
      intelligent, saurans have a knack for making themselves appear dimwitted
      due to thier enormous size and often slow response to a good joke.  Saurans
      are generally peaceful but few exceed their ability to fight.</div>,
    strength: 11,
    constitution: 11,
    dexterity: 9,
    charisma: 5,
    intelligence: 6,
    wisdom: 7,
    arms: 2,
    legs: 2,
    fingersPerHand: 4,
    nightVision: 4,
    questRace: false,
    language: 'Draklar',
  },
  'troll': {
    name: 'Troll',
    description: <div>Trolls are large, monstrous creatures often mistaken for orcs. All trolls
      have enormous, bulbous noses and are covered in warts. Their skin can range
      from black to brown to green to white, and what little hair they have on
      their heads is usually black. Trolls are among the least intelligent of the
      races, but their incredible ability to heal from almost any wound makes up
      for this.</div>,
    characteristics: <div>Trolls usually live in caves in small family groups. They hunt and eat
      anything they can catch, including other trolls. Often, each group will
      decorate itself with small bone piercings from their most impressive kill.
      Trolls have no real sense of the world at large and exist primarily to eat.</div>,
    strength: 12,
    constitution: 13,
    dexterity: 6,
    charisma: 3,
    intelligence: 4,
    wisdom: 3,
    arms: 2,
    legs: 2,
    fingersPerHand: 6,
    nightVision: 3,
    questRace: false,
    language: 'Grunkish',
  },
  'vampire': {
    name: 'Vampire',
    description: <div>Vampires have the build of a tall, attractive human, only with pale white
      skin. Their hair and eye colors are the same as a human, except some
      vampires have red, yellow or orange eyes. Vampires stand a little over 2
      meters and have generally muscular and fit bodies.</div>,
    characteristics: <div>The average Vampire is much quicker than a human and its strength is
      rivaled only by some of the super-strong races. Vampires are not
      necessarily evil beings, simply dead, or rather, undead. Vampires have no
      wings but have a natural ability to fly. It is rumored that a Vampire's
      ability, power, and attacks come from his or her age.</div>,
    strength: 14,
    constitution: 16,
    dexterity: 12,
    charisma: 9,
    intelligence: 9,
    wisdom: 6,
    arms: 2,
    legs: 2,
    fingersPerHand: 5,
    nightVision: 3,
    questRace: true,
    language: 'Selunian',
  },
  'weimic': {
    name: 'Weimic',
    description: <div>Weimics average 2 meters in height and nearly the same length from front to
      back. Much like a centaur, weimics have the upper body of a human and the
      lower torso and legs of large feline. Their coats are usually gold in color
      and most male weimics have incredible manes. They are strong, fast and
      quite durable. Weimics are beautiful creatures but are quite feared by
      other races which tends to reflect on their charisma.</div>,
    characteristics: <div>The weimics of Primal Darkness are thought to have originated long before
      the age of Carnage. While usually unpredictable due to their animalistic
      instincts, they tend to have a fierce sense of loyalty and are far from
      unintelligent.</div>,
    strength: 10,
    constitution: 9,
    dexterity: 11,
    charisma: 6,
    intelligence: 7,
    wisdom: 6,
    arms: 2,
    legs: 4,
    fingersPerHand: 4,
    nightVision: 4,
    questRace: false,
    language: 'Felimenium',
  },
}

export default races;