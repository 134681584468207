import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router"
import RaceData from "./race_data"
import StatTable from "./stat-table"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import NotFound from "../../errors/404"
import {withStyles} from "@material-ui/core"
import RaceList from "./race-list"
import Divider from "@material-ui/core/Divider"

const style = theme => ({
  root: {
    '& h6': {
      marginTop: theme.spacing(2),
    }
  },
  stats: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  raceList: {
    marginTop: theme.spacing(2),
    '& hr': {
      marginBottom: theme.spacing(2),
    }
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
});

class ViewRace extends Component {
  render() {
    const { match, classes } = this.props;
    const race = RaceData[match.params.race];

    if (!race) {
      return <NotFound />
    }

    return (
      <Grid container className={classes.root}>
        <Typography variant={'h3'}>{race.name}</Typography>
        <StatTable race={race} className={classes.stats}/>
        <Typography variant={'h6'}>Description</Typography>
        <Typography variant={"body1"}>{race.description}</Typography>
        <Typography variant={'h6'}>Characteristics</Typography>
        <Typography variant={"body1"}>{race.characteristics}</Typography>
        <Grid item sm={12} className={classes.raceList}>
          <Divider />
          <RaceList />
        </Grid>
      </Grid>
    );
  }
}

ViewRace.propTypes = {};

ViewRace = withRouter(ViewRace);

ViewRace = withStyles(style)(ViewRace);


export default ViewRace;