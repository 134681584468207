import React, { Component } from 'react';
import {Link as RouterLink} from "react-router-dom"
import {withStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"

const style = theme => ({
  root: {
    background: '#332C20',
    color: '#F1D6AC',
    borderTop: '2px solid #5f5540',
    fontSize: 14,
    paddingBottom: theme.spacing(2),
    textAlign: 'center',

    '& a, & a:hover': {
      fontSize: 16,
      color: '#F1D6AC',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
  menu: {
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(1),
    '& li': {
      float: 'left',
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
      },
    }
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Grid container className={classes.root} direction={'column'} alignContent={'center'} alignItems={'center'} spacing={1}>
          <Grid item>
            <ul className={classes.menu}>
              <li><Link component={RouterLink} to="/help">Help</Link></li>
              <li><Link component={RouterLink} to="/terms-of-service">Terms of Service</Link></li>
              <li><Link component={RouterLink} to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link href="https://grapevine.haus/games/PD/stats" target="_blank">Stats</Link></li>
            </ul>
          </Grid>

          <Grid item>
            &copy; 2019 Primal Darkness Productions. All Rights Reserved.<br/>
            Primal Darkness runs on <Link href="https://www.fluffos.info/" target="_blank">FluffOS</Link>.
          </Grid>
          <Grid item>
            <Link href="https://www.patreon.com/bePatron?u=17808183" data-patreon-widget-type="become-patron-button">Support us by becoming a Patron!</Link>
            <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>

          </Grid>
        </Grid>
    );
  }
}

Footer = withStyles(style)(Footer);

export default Footer;
