import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import {withStyles} from "@material-ui/core"
import ClassList from "./class-list"

const style = theme => ({

});

class Classes extends Component {
  render() {
    return (
      <Grid>
        <Typography>
          Insert introduction to classes
        </Typography>
        <Divider />
        <ClassList />
      </Grid>
    );
  }
}

Classes.propTypes = {};

Classes = withStyles(style)(Classes);

export default Classes;