import React from "react"

const classes = {
  clergy: {
    name: 'Clergy',
    description: <div>
      Clergy form a very diverse group whose only common theme is that they
      have devoted their lives to their faith.  Clergy generally should have
      high wisdom and intelligence to aid them in their magical prayers.
      They spend much of their time worshiping whatever it is they believe in.
      There are many different types of clery to choose from.
    </div>,
    location: <div>The clergy hall is reported to be to the east of the square.</div>,
    subclasses: {
      cleric: {
        name: 'Cleric',
        description: <div>
          The healers of Primal Darkness, clerics pray to their diety
          to grant them healing and protection. Although not actually
          versed in magic, prayers answered by their diety giving them
          the appearance of having magic.  The cleric is known for their
          faith and goodness.
        </div>,
        location: <div>
          The cleric hall is rumoured to be in a place of healing on
          the east side of town.
        </div>
      },
      druid: {
        name: 'Druid',
        description: <div>
          The believers in nature of Primal Darkness, the druid work
          to bring unity to the world through nature.  They strive to
          protect and be at peace with nature.  They have many ways of
          effecting nature and having all things natural answer their
          prayers.  The druid is known for their devotion and goodness.
        </div>,
        location: <div>
          The druid hall is rumoured to be in a grove to north outside
          of town.
        </div>
      },
      kataan: {
        name: 'Kataan',
        description: <div>
          The demon worshipers of Primal Darkness, kataans seek to
          spread evil and chaos.  The demons of the underworld answer
          their prayers and do their bidding. The kataans are known
          for their bitterness and great hatred of all nonbelievers.
        </div>,
        location: <div>
          The kataan hall is rumoured to be in the resting place of
          the dead.
        </div>
      },
      monk: {
        name: 'Monk',
        description: <div>
          The martial artists of Primal Darkness, monks believe in the
          unity of the body.  They worship their diety who in return
          grants their prayers and teaches them of chi.  They strive
          for knowledge and understanding of their bodies and the ways
          of hand to hand combat.  Monks are known for their goodness
          and faith.
        </div>,
        location: <div>
          The monk hall is rumoured to be in a monastery.
        </div>
      },
    },
  },
  mage: {
    name: 'Mage',
    description: <div>
      Mages have devoted their lives to learning magic.  They have no powers of
      faith, and their time spent learning magic takes away from any time they
      might have spent learning fighting skills.  They are very capable of
      learning, however, offensive type spells and spells of a conjuring nature.
      They are generally not tremendously pious nor are they terribly wicked.
      Mages must, however, be fairly intelligent and wise.
    </div>,
    location: <div>
      The mage hall is reported to be to the east of the square.
    </div>,
    subclasses: {
      necromancer: {
        name: 'Necromancer',
        description: <div>
          The black magi of Primal Darkness, necromancers dabble in the
          dark arts of death magic. Necromancers have the magic to summon
          and control the undead. Along with their knowledge of poisons
          and disease, the necromancer is a force of evil.
        </div>,
        location: <div>The necromancers hall is rumoured to be in the resting
          place of the dead.</div>
      },
      pyromancer: {
        name: 'Pyromancer',
        description: <div>
          The fire dancers of Primal Darkness, pyromancers command great
          control over magics of fire. With their knowledge, pyromancers
          can call on fire to bend to their will causing great damage
          and suffering to their enemies.
        </div>,
        location: <div>The pyromancer hall is rumoured to be to the north in town.</div>
      },
      sorceror: {
        name: 'Sorceror',
        description: <div>
          The psionicists of Primal Darkness, sorcerors have learned to
          harness their own mental energy. By shaping it into semi-physical
          forms, they can use telekinetic force or even attack the mind
          directly, causing intense mental trauma for which there is little
          defense.
        </div>,
        location: <div>The sorceror hall is rumoured to be to the north in town.</div>
      },
      wizard: {
        name: 'Wizard',
        description: <div>
          The thaumaturgist of Primal Darkness, wizards choose
          to control the mana of the world around them. Changing the
          very essence of things to their will, wizards command raw
          magics most would not dare to touch.
        </div>,
        location: <div>The wizard hall is rumoured to be to the north in town.</div>
      },
    },
  },
  fighter: {
    name: 'Fighter',
    location: <div>
      The fighter hall is reported to be not to far south and west of the square.
    </div>,
    description: <div>
      Fighters form a very diverse group whose only common theme is that they
      have devoted their lives to learning the skills of combat.  Players of this
      class may be good, evil, or neutral.  They may serve a pious king, or they
      may serve only themselves.  Fighters generally should have high strength
      and constitution to aid them in their physical battles.  They spend much
      of their learning time studying how to use weapons of different types, as
      well as mastering hand-to-hand combat and self-defense.  There are many
      different type of fighters to choose from.
    </div>,
    subclasses: {
      berserker: {
        name: 'Berserker',
        description: <div>
          The scrappers of Primal Darkness, berserkers prefer to fight
          hand to hand.  Superstitious of magic, berserkers shun all
          magical items. The battlelust of a berserker is legendary.
        </div>,
        location: <div>The berserker hall is rumoured to be in a rowdy pub on the
          north side of town.</div>
      },
      paladin: {
        name: 'Paladin',
        description: <div>
          The fire dancers of Primal Darkness, pyromancers command great
          control over magics of fire. With their knowledge, pyromancers
          can call on fire to bend to their will causing great damage
          and suffering to their enemies.
        </div>,
        location: <div>The paladin hall is rumoured to be in the holy place of Tirun.</div>
      },
      antipaladin: {
        name: 'Antipaladin',
        description: <div>
          The corrupted knights of Primal Darkness, antipaladins pray
          to a deity of darkness for more destructive power. Many are
          half-sane zealots. Their sadistic nature is feared by all.
        </div>,
        location: <div>The antipaladin hall is rumoured to be in the resting
          place of the dead.</div>
      },
      ranger: {
        name: 'Ranger',
        description: <div>
          The woodland protector of Primal Darkness, rangers have many
          skills that have nothing and everything to do with fighting.
          Their devotion to the woodlands has gained them respect.
        </div>,
        location: <div>The ranger hall is rumoured to be north out of town.</div>
      },
      warrior: {
        name: 'Warrior',
        description: <div>
          The skilled weaponsmaster of Primal Darkness, warriors choose
          many different ways to use their skills.  Some sell their
          services while other protect one and all in the lands.  A
          warrior's skills are known through out the world to be deadly.
        </div>,
        location: <div>The warrior hall is rumoured to be in town to the east and slightly south.</div>
      },
    },
  },
  rogue: {
    name: 'Rogue',
    description: <div>
      Rogues live on the misery of others, whether it is directly through the
      killing of people in a random sort of way, or through stealing and picking
      pockets.  These people often become assassins and thieves, and they get
      their experience in any way they see fit.  They are not the best of
      fighters nor do they have time to learn the magical arts, but they are
      very adept at the arts of deception.  Races with high dexterity make the
      best rogues.
    </div>,
    location: <div>
      Rogues are rumoured to have hidden their hall somewhere to the northwest...
    </div>,
    subclasses: {
      assassin: {
        name: 'Assassin',
        description: <div>
          The hired killers of Primal Darkness, assassins are the
          masters of disguise and deception. Striving to sell their trade
          to the highest bidder, assassins have no morals nor any goodness
          in them.  Assassins are known to be shrewd and skilled in killing.
        </div>,
        location: <div></div>
      },
      scout: {
        name: 'Scout',
        description: <div>
          The forerunners of Primal Darkness, scouts sell their skills to
          anyone willing to pay.  They show no allegiance to anyone.  Scouts
          are known for their sense of direction and knowledge of the
          country side.
        </div>,
        location: <div></div>
      },
      thief: {
        name: 'Thief',
        description: <div>
          The pickpockets of Primal Darkness, thieves seek to get
          rich through stealing from others. Using their knowledge of stealth
          and disguise they pick pockets and steal items. Thieves are known
          for their greed and cowardice.
        </div>,
        location: <div>The antipaladin hall is rumoured to be in the resting
          place of the dead.</div>
      },
      thug: {
        name: 'Thug',
        description: <div>
          The swindlers of Primal Darkness, thugs seek to make it on
          the suffering of others.  Using their skill of stealth they strive
          to do anything they can to make their lives easier.  They sometimes
          hire themselves out as strongarms only to end up betraying the ones
          who hired them.  Thugs are known for being double crossing rogues.
        </div>,
        location: <div></div>
      },
    },
  },
  wanderer: {
    name: 'Wanderer',
    description: <div>
      Wanderers are people who spend their lives never at peace with their
      surroundings and have adopted a strong wanderlust from an early age.
      Wandering the land in search of adventure, a wanderer is never content
      to settle for long.
    </div>,
    location: <div>
      The wanderer hall is reported to be not too far north and west of the square.
    </div>,
    subclasses: {
      bard: {
        name: 'Bard',
        description: <div>
          The hired killers of Primal Darkness, assassins are the
          masters of disguise and deception. Striving to sell their trade
          to the highest bidder, assassins have no morals nor any goodness
          in them.  Assassins are known to be shrewd and skilled in killing.
        </div>,
        location: <div>
          The bard hall is rumoured to be in a place of gathering and
          spirits.
        </div>
      },
      gypsy: {
        name: 'Gypsy',
        description: <div>
          The vagabonds of Primal Darkness, the gypsys strive
          to keep their herritage pure.  They make their way by telling
          fortunes, singing, and a bit of thievery. Gypsys have no
          aliiances to any but their own. Gypsys are known for their
          cunning and mystical nature.
        </div>,
        location: <div>The hall of gypsies is rumoured to be east out of town.</div>
      },
      jester: {
        name: 'Jester',
        description: <div>
          The entertainers of Primal Darkness, jesters seek to
          entertain all those around them.  They are tricksters and
          con artists. They are known primarialy for their acts of
          entertainment and tomfoolery.
        </div>,
        location: <div>The hall of jesters is rumoured to be in town to the south.</div>
      },
      swashbuckler: {
        name: 'Swashbuckler',
        description: <div>
          The blade master of Primal Darkness, swashbucklers are
          skilled in the use of the blade.  They use this skill to
          entertain and fight for whatever cause they believe just.
          Swashbucklers are known for their dashing looks and acts
          of heroism.
        </div>,
        location: <div>The swashbuckler hall is rumoured to be south of town around the docks.</div>
      },
    },
  },
}

export default classes;
