import React, { Component } from 'react';
import Page from "../page/page"
import {Switch} from "react-router"
import {Route} from "react-router-dom"
import Races from "./race/races"
import ViewRace from "./race/view-race"
import NotFound from "../errors/404"
import Classes from "./classes/classes"
import ViewClass from "./classes/view-class"
import ViewSubclass from "./classes/view-subclass"
import Quest from "./quests/quest"
import GettingStarted from "./getting-started"
import Faq from "./faq/faq"
import Redirect from "react-router/Redirect"

class Help extends Component {
  render() {
    return (
      <Page>
        <Switch>
          <Route path="/help/classes/:className/subclasses/:subclassName" component={ViewSubclass} />
          <Route path="/help/classes/:className" component={ViewClass} />
          <Route path="/help/classes" component={Classes} />
          <Route path="/help/races/:race" component={ViewRace} />
          <Route path="/help/races" component={Races} />
          <Route path="/help/quests" component={Quest} />
          <Route path="/help/faq" component={Faq} />
          <Redirect from={'/help/getting-started'} to={'/help/faq'} />
          <Route path="/" component={NotFound} />
          <Route path="/help/getting-started" component={GettingStarted} />
        </Switch>
      </Page>
    );
  }
}

export default Help;
