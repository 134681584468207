import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RaceData from "./race_data"
import Link from "@material-ui/core/Link"
import {Link as RouterLink} from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core"

const style = theme => ({
  link: {
    // margin: theme.spacing(2),
  }
});

class RaceList extends Component {
  render() {
    const { classes } = this.props;

    const races = Object.keys(RaceData).sort().map((k) =>
      <Grid item>
        <Link key={k}
              component={RouterLink}
              to={`/help/races/${k}`}
              className={classes.link}>{RaceData[k].name}</Link>
      </Grid>)

    return (
      <Grid container direction={'row'} spacing={3}>
        {races}
      </Grid>
    );
  }
}

RaceList.propTypes = {};

RaceList = withStyles(style)(RaceList);

export default RaceList;