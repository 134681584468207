import React from 'react';

const areas = {
  aekari: {
    name: 'Aekari',
    author: 'Danovae',
  },
  new_arlon: {
    name: 'New Arlon',
    author: 'Vian',
  },
  past_arlon: {
    name: 'Past Arlon',
    author: 'Vian',
  },
  arlon_zoo: {
    name: 'Arlon Zoo',
    author: 'Cortoth',
  },
  campgrounds: {
    name: 'Campgrounds',
    author: 'Durst',
  },
  citrin: {
    name: 'Citrin Forest',
    author: 'Whit',
  },
  distopia: {
    name: 'Distopia',
    author: 'Hermes',
  },
  dragonlands: {
    name: 'Dragonlands',
    author: 'Pyro & Nulvect',
  },
  dragoncaves: {
    name: 'Dragonlands Caves',
    author: 'Amun & Vian',
  },
  dunsinane: {
    name: 'Dunsinane',
    author: 'Powerman',
  },
  elvenvillage: {
    name: 'Elven Village',
    author: 'Amarael',
  },
  forbiddenforest: {
    name: 'Forbidden Forest',
    author: 'Detach & Soulfly',
  },
  goblinmines: {
    name: 'Goblin Mines',
    author: 'Marduk',
  },
  helgrath: {
    name: 'Helgrath',
    author: 'Vhaldir and Staff',
  },
  inferi: {
    name: 'Inferi Island',
    author: 'Amun',
  },
  lodos: {
    name: 'Lodos',
    author: 'Inferno',
  },
  lodosfarm: {
    name: 'Lodos Farm',
    author: 'Crem',
  },
  lostlands: {
    name: 'Lost Lands',
    author: 'Durst',
  },
  merkkirri: {
    name: 'Merkkirri',
    author: 'Asmodeus',
  },
  muabarkdesert: {
    name: 'Muabark Desert',
    author: 'Durst',
  },
  orchard: {
    name: 'North Tirun Orchard',
    author: 'Daos',
  },
  purgatory: {
    name: 'Purgatory',
    author: 'Wraith',
  },
  rhondale: {
    name: 'Rhondale',
    author: 'Prator',
  },
  ridarin: {
    name: 'Ridarin',
    author: 'Nulvect',
  },
  roston: {
    name: 'Roston',
    author: 'Detach',
  },
  saahr: {
    name: 'Saahr',
    author: 'Nulvect',
  },
  tenebrae: {
    name: 'Tenebrae',
    author: 'Nightshade',
  },
  tirun: {
    name: 'Tirun',
    author: 'Stormbringer',
  },
  casino: {
    name: 'Tirun Casino',
    author: 'Ace',
  },
  tirunforest: {
    name: 'Tirun Forest',
    author: 'Stormbringer',
  },
  tirungraveyard: {
    name: 'Tirun Graveyard',
    author: 'Nightmare MudLib',
  },
  tirunsewers: {
    name: 'Tirun Sewers',
    author: 'Nightshade',
  },
  tirunbay: {
    name: 'Tirun Bay',
    author: 'Ironman',
  },
  trollcaves: {
    name: 'Troll Caves',
    author: 'Wolfspirit',
  },
  zolenia: {
    name: 'Zolenia',
    author: 'Vian',
  },
}

export default areas;