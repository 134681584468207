import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid"
import { StarBorder as StarBorderIcon } from '@material-ui/icons'
import {createStyles, makeStyles} from "@material-ui/core"
import {blue} from "@material-ui/core/colors"

const rangeEntryStyles = theme => createStyles({
  icon: {
    color: blue[600],
    fontSize: 16,
    '&:nth-child(3n)': {
      paddingRight: theme.spacing(1),
    }
  }
})

const RangeEntry = (props) => {
  const icons = []

  const classes = makeStyles(rangeEntryStyles)()

  console.log(classes)

  for (let i=0; i < props.value; i ++) {
    icons.push(<StarBorderIcon key={i} className={classes.icon} />)
  }

  return (
    <Grid container direction={'row'}>
      <Grid item md={5}>{props.label}</Grid>
      <Grid item md={7}>
        <Grid container>
          {icons}
        </Grid>
      </Grid>
    </Grid>
  )
}

const Entry = (props) => {
  return (
    <Grid container direction={'row'}>
      <Grid item md={5}>{props.label}</Grid>
      <Grid item md={7}>{props.value}</Grid>
    </Grid>
  )
}

class StatTable extends Component {
  render() {
    const { race, ...other } = this.props;
    return (
      <Grid container direction={'row'} {...other}>
        <Grid item xs={12} sm={12} md={6}>
          <RangeEntry label="Strength:" value={race.strength}/>
          <RangeEntry label="Constitution:" value={race.constitution}/>
          <RangeEntry label="Dexterity:" value={race.dexterity}/>
          <RangeEntry label="Charisma:" value={race.charisma}/>
          <RangeEntry label="Intelligence:" value={race.intelligence}/>
          <RangeEntry label="Wisdom:" value={race.wisdom}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Entry label="Number of arms:" value={race.arms}/>
          <Entry label="Number of legs:" value={race.legs}/>
          <Entry label="Number of fingers p/ hand:" value={race.fingersPerHand}/>
          <RangeEntry label="Night Vision:" value={race.fingersPerHand}/>
          <Entry label="Quest Race:" value={race.questRace ? 'Yes' : 'No'}/>
          <Entry label="Language:" value={race.language}/>
        </Grid>
      </Grid>
    );
  }
}

StatTable.propTypes = {
  className: PropTypes.string,

  race: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    strength: PropTypes.number.isRequired,
    constitution: PropTypes.number.isRequired,
    charisma: PropTypes.number.isRequired,
    intelligence: PropTypes.number.isRequired,
    wisdom: PropTypes.number.isRequired,
    arms: PropTypes.number.isRequired,
    legs: PropTypes.number.isRequired,
    fingersPerHand: PropTypes.number.isRequired,
    nightVision: PropTypes.number.isRequired,
    questRace: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
  })

};

export default StatTable;