import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router"
import ClassData from "./class_data"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import NotFound from "../../errors/404"
import {withStyles} from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import ClassList from "./class-list"
import SubclassList from "./subclass-list"

const style = theme => ({
  root: {
    '& h6': {
      marginTop: theme.spacing(2),
    }
  },
  stats: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  raceList: {
    marginTop: theme.spacing(2),
    '& hr': {
      marginBottom: theme.spacing(2),
    }
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
});

class ViewClass extends Component {
  render() {
    const { match, classes } = this.props;
    const cls = ClassData[match.params.className];

    if (!cls) {
      return <NotFound />
    }

    return (
      <Grid container className={classes.root} direction={'column'}>
        <Typography variant={'h3'}>{cls.name}</Typography>
        <Typography variant={'h6'}>Description</Typography>
        <Typography variant={"body1"}>{cls.description}</Typography>
        <Typography variant={'h6'}>Location</Typography>
        <Typography variant={"body1"}>{cls.location}</Typography>
        <Typography variant={'h6'}>Subclasses</Typography>
        <SubclassList cls={match.params.className}/>
        <Grid item sm={12} className={classes.raceList}>
          <Divider />
          <ClassList />
        </Grid>
      </Grid>
    );
  }
}

ViewClass.propTypes = {};

ViewClass = withRouter(ViewClass);

ViewClass = withStyles(style)(ViewClass);


export default ViewClass;