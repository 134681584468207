import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import { Link as RouterLink } from 'react-router-dom'
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import {withRouter} from "react-router"
import {withStyles} from "@material-ui/core"
import Link from "@material-ui/core/Link"
import RaceData from './race_data'
import StatTable from "./stat-table"
import RaceList from "./race-list"

const style = theme => ({

});

class Races extends Component {
  render() {
    return (
      <Grid>
        <Typography>
          Insert introduction to races
        </Typography>
        <Divider />
        <RaceList />
      </Grid>
    );
  }
}

Races.propTypes = {};

Races = withRouter(Races);

Races = withStyles(style)(Races);

export default Races;