import React, { Component } from 'react';
import Page from "../page/page"
import Grid from "@material-ui/core/Grid"

class NotFound extends Component {
  render() {
    return (
        <Grid container >
          Page Not Found
        </Grid>
    );
  }
}

export default NotFound;
