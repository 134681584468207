import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography"

class Quest extends Component {
  render() {
    return (
      <div>
        <Typography variant={'h3'}>
          Quests
        </Typography>
        <Typography variant={'body1'}>
          Quests are mysterious journeys undertaken by Players in order to achieve some
          desired reward.  The most prestigious races are available only through the
          completion of Quests.  On Primal Darkness, the secrecy of the information
          needed to solve any given  Quest is incredibly valuable to the health of the
          Multi-User Dimension.  Rewards can only justifiably be made as valuable as they
          are rare.  In the future, it may be necessary for the Administration to devalue
          rewards based on their decreasing rarity  for the sake of Balance.  The
          following rules have been designed to  help ensure that Quest rewards will
          remain worthwhile:
        </Typography>
        <ol>
          <li>
            It is illegal to share information about Quests or Mini-Quests with
            other Players for any reason, with the exception of information shared in
            the context described in #2.
          </li>
          <li>
            Two Players may Quest together, but both must be active in the process.
          </li>
          <li>
            It is illegal for a second Player to join a Quest near its completion.
            Two Players may only co-operate  together during a Quest if they do so at
            what is perceived to be its beginning. When in doubt, Quest alone.
          </li>
          <li>
            Sub-hall locations are considered quest information and thus falls under
            the rules governing quest information.
          </li>
        </ol>
      </div>
    );
  }
}

Quest.propTypes = {};

export default Quest;