import React, { Component } from 'react';
import Page from "../page/page"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core"
import AreaData from '../help/areas/area_data'
import Link from "@material-ui/core/Link"

const style = theme => ({
  credits: {
    padding: theme.spacing(3),
  }
});

const creditStyle = theme => ({
  title: {
    fontWeight: 'bold',
  },
  author: {

  }
});

const AreaCredit = (props) => {
  const { classes, author, area } = props;

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <Grid container direction={'row'}>
        <Grid item xs={5} sm={5} md={6} lg={4} className={classes.title}>{area}</Grid>
        <Grid item xs={7} sm={7} md={6} md={4} className={classes.author}>{author}</Grid>
      </Grid>
    </Grid>
  )
};

const StyledAreaCredit = withStyles(creditStyle)(AreaCredit);

class AboutUs extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Page>
          <Typography variant={'h3'}>About Us</Typography>
          <Typography variant={'body2'}>
            Primal Darkness was created over the last 20 years by a large number of staff
            and dedicated players.
          </Typography>
          <Divider />
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <Typography variant={'h6'}>Hosting</Typography>
              <Typography variant={'body1'}>
                Hosting is generously provided by <Link href={'https://justinmccormick.com'} target={'_blank'}>Whit</Link>,
                Stormbringer and our generous <Link href={'https://www.patreon.com/primaldarkness'} target={'_blank'}>patrons</Link>.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Administrators</Typography>
              <ul>
                <li>Daos - daos@primaldarkness.com</li>
                <li>Stormbringer - stormbringer@primaldarkness.com</li>
                <li>Whit - whit@primaldarkness.com</li>
              </ul>
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Immortals</Typography>
              <ul>
                <li>Vian</li>
                <li>Ymir</li>
              </ul>
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Area Credits</Typography>
              <Grid container className={classes.credits}>
                {Object.keys(AreaData).map(key => <StyledAreaCredit area={AreaData[key].name} author={AreaData[key].author} />)}
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Library and Security</Typography>
              <Grid container className={classes.credits}>
                <StyledAreaCredit area={'Delivery System'} author={'Drackmire'} />
                <StyledAreaCredit area={'Extra Spells'} author={'Kaknal'} />
                <StyledAreaCredit area={'Guild System'} author={'Whit'} />
                <StyledAreaCredit area={'Gaming Events'} author={'Daos'} />
                <StyledAreaCredit area={'Guild Payments'} author={'Nesoo'} />
                <StyledAreaCredit area={'Guild Updates'} author={'Daboura'} />
                <StyledAreaCredit area={'Ocean System'} author={'Whit'} />
                <StyledAreaCredit area={'Player Balance'} author={'Nulvect'} />
                <StyledAreaCredit area={'Security'} author={'Whit'} />
                <StyledAreaCredit area={'Senses System'} author={'Ironman'} />
                <StyledAreaCredit area={'Sky System'} author={'Whit'} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Retired Staff</Typography>
              <Typography variant={'body1'}>
                Ace, Amarael, Asmodeus, Azire, Daboura, Detach, Helena,
                Hepualahaole, Hermes, Inferno, Ironman, Ithilgore,
                Kabaal, Levi, Marduk, Nesoo, Nightshade, Pyro,
                Rubicante, Seeker, Shinkoro, Slinker, Suluial, Venenum,
                Wiz, Wraith
              </Typography>
            </Grid>
          </Grid>
        </Page>
    );
  }
}

AboutUs = withStyles(style)(AboutUs);

export default AboutUs;
