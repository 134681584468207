import React, { Component } from 'react';
import Header from "./header"
import Footer from "./footer"
import LeftNavigation from "./left-navigation"
import './page.scss'
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core"

const style = theme => ({
  root: {
  },
  content: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    }
  }
})

class Page extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Grid container direction={'row'} className={classes.root}>
          <Header />
          <Grid container direction={'row'}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <LeftNavigation/>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={9} className={classes.content}>
              {this.props.children}
            </Grid>
          </Grid>
          <Footer />
        </Grid>
    );
  }
}

Page = withStyles(style)(Page);

export default Page;
