import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createStore } from 'redux'
import {Provider} from "react-redux"
import {Switch} from "react-router"
import Home from "./home/home"
import Help from "./help/help"
import AboutUs from "./aboutus/about-us"
import Terms from "./aboutus/terms"
import NotFound from "./errors/404"
import Page from "./page/page"
import PrivacyPolicy from "./aboutus/privacy-policy"
import Guilds from "./guilds/guilds"

const store = createStore(() => {})

const NotFoundPage = props => {
  return <Page><NotFound /></Page>
}

class App extends Component {
  render() {
    return (
        <Provider store={store}>
          <Router>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/help" component={Help} />
                <Route path="/about" component={AboutUs} />
                <Route path="/guilds" component={Guilds} />
                <Route path="/terms-of-service" component={Terms} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/" component={NotFoundPage} />
              </Switch>
          </Router>
        </Provider>
    );
  }
}

export default App;
