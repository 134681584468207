import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FaqData from './faq_data'
import {createStyles, makeStyles, withStyles} from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Link from "@material-ui/core/Link"

const entryStyle = makeStyles(createStyles(theme  => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  content: {
    '& p': {
      marginBottom: theme.spacing(2),
    }
  }
})));

const style = theme => ({
  toc: {
    marginLeft: theme.spacing(2),
  }
});

const Entry = (props) => {
  const classes = entryStyle();

  return (
    <Grid container direction="column" className={classes.root}>
      <a id={props.index+1} />
      <Typography variant={'h6'}>#{props.index+1} {props.title}</Typography>
      <Typography variant={'body1'} className={classes.content}>{props.content}</Typography>
    </Grid>
  )
}

class Faq extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid>
        <Typography variant={'h3'}>Frequently Asked Questions</Typography>
        <ul className={classes.toc}>
          {FaqData.map((it, i) => <li key={i}><Link href={`#${i+1}`}>{it.title}</Link></li>)}
        </ul>
        <Divider fullWidth/>
        {FaqData.map((it, i) => <Entry key={i} index={i} {...it}/>)}
      </Grid>
    );
  }
}

Faq.propTypes = {};

Faq = withStyles(style)(Faq);

export default Faq;