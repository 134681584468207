import React, { Component } from 'react';
import Page from "../page/page"
import Typography from "@material-ui/core/Typography"

class Terms extends Component {
  render() {
    return (
        <Page>
          <Typography variant={'h3'}>Game Rules</Typography>
          <p>
          1. You may not harass anyone. If they ask you to stop, just apologize
          and be nice. This is supposed to be a fun experience. There is a harass
          log. The punishment for harassment varies but is never taken lightly.
          </p>
          <p>
          2. The Primal Darkness Staff is not allowed to help mortals in any way
          unless it is to fix some kind of bug. Any player and/or staff member
          who breaks this rule will be instantly rid and banned for life. All of
          the Staff are to be respected. DO NOT ask a Staff member information
          that you know would be breaking the rules.
          </p>
          <p>
          3. Although we try hard to make sure everything in the game works right,
          sometimes the code has unforseen bugs. Exploiting bugs is not allowed.
          Anyone who exploits bugs shall have all their characters deleted for
          cheating. If you find a bug, use the bug command to report it and never
          try to use the bug again. A bug may consist of but is not limited to:
          gaining experience from doing nothing; advancing skills, stats, or
          levels without using exp or doing a quest; fighting a mob that doesn't
          fight back; casting a spell on an object repeatedly to gain exp;
          selling an item over and over and it does not leave your inventory;
          buying an item at a shop for 0 money; etc...
          </p>
          <p>
          4. Player killing is allowed on Primal Darkness. There is a justice system
          in play and it is always evolving. Multiple PKs of the same character is
          harassment. Going on a PK spree is harassment. You must be level 20 before
          you can pk or be pked. Members of the same guild may not pick one person
          to pk multiple times. You or a member of the guild you are in may only
          pk a single player once per 5 day period. PK in a nopk zone such as Tirun
          is also illegal and is considered bug abuse.
          </p>
          <p>
          5. It is illegal to give away any quest/mini quest information for any
          reason. Quests may be done with another player, but both players must be
          activly questing. You may not finish a quest, and the other person just
          solves the end of it.
          </p>
          <p>
          6. Multi-Playing is illegal. You may not be logged in with more than one
          character at any given time, with the exception of immortals and test
          characters. You may not log in with one character, to get weapons or
          any other items for your alternates. You may not switch characters if
          a friend needs anything from your alt. You may not, at anytime,
          avenge a death with an alt. When switching characters, you must log your
          character off completely before logging another one on, link-dead characters
          on at the same time are multi-playing.
          </p>
          <p>
          7. Spamming lines, says, tells, shouts, boards, or any other form of
          communication is not allowed. Spamming is repeating something over
          and over to annoy others. Spamming is harassment.
          </p>
          <p>
          8. The auction line: Do not continually bid on an item you have put up for
          auction, if you put something up for auction, bid on it once for the minimum
          price you wish to sell it for and do not bid on it again. Do not put
          worthless items such as stones, oil, meat, or things of this nature
          up for auction. Do not continually try to auction the same item over and
          over. If no one bids on your item the first time around, you should wait at
          least 10 minutes before putting that item up for auction again.
          </p>
          <p>
          9. Profane language on open lines and boards is not allowed. Using it in
          says, tells and on party lines is ok as long as it does not get out of
          hand. If someone asks you to stop using that language to them or around
          them, please respect their wishes. If you repeatedly ignore someone
          asking you to stop using profanity around them, it becomes harassment.
          </p>
          <p>
          10. Stealing kills is against the rules. If someone is killing a mob go find
          another to kill. You can't join them, help them, take their kill while
          they rest one room away, or loot the corpse when they are done unless
          you are partied together.
          </p>
        </Page>
    );
  }
}

export default Terms;
