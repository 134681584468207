import React, {Component} from 'react';
import PropTypes from 'prop-types';

class GettingStarted extends Component {
  render() {
    return (
      <div>

      </div>
    );
  }
}

GettingStarted.propTypes = {};

export default GettingStarted;