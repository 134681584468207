import React, { Component } from 'react';
import Header from "../page/header";
import {Link as RouterLink} from "react-router-dom";
import {connect} from 'react-redux';
import Footer from "../page/footer";
import cls from 'cls';
import {withStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import {grey} from "@material-ui/core/colors"
import Hidden from "@material-ui/core/Hidden"

const splashUrl = '//source.unsplash.com/collection/8873128';

const style = theme => ({
  root: {

  },
  hero: {
    background: 'url(/img/map.png) no-repeat',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.background.default,
    // background: `url(${splashUrl}/1920x1200) no-repeat`,
    backgroundAttachment: 'fixed',
    width: '100vw',
    height: '100vh',
    minHeight: 600,
  },
  heroContent: {
    zIndex: 30,
    color: '#1d160a',
    margin: theme.spacing(5),
    padding: theme.spacing(5),
    height: 400,
  },
  heroBackground: {
    borderRadius: 5,
    opacity: 0.8,
    marginTop: "-400px",
    zIndex: 20,
    backgroundColor: grey[600],
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
  },
  heroText: {
    textAlign: 'center',
    margin: 'auto',
    marginBottom: theme.spacing(5),
    fontStyle: 'italic',
  },
  heroLink: {
    color: '#1d160a',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  heroHeading: {
    margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px auto`,
    fontSize: '5em',
  },
  card: {
    borderRadius: 5,
    color: '#FFFFFF',
    background: '#1d160a',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    flexGrow: 1,
    '& p': {
      marginBottom: theme.spacing(2)
    }
  },
  cardHeading: {
    color: '#655940',
    fontFamily: 'arial',
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  map: {
    width: 200,
    height: 150,
  }
})

class Home extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Grid id="home">
          <div className={classes.hero}>
            <Grid className={cls(classes.hero)} container>
              <Header />
              <Grid container direction={'row'} justify={'center'}>
                <Grid item xs={12} sm={10} md={8} lg={8} className={classes.heroContent}>
                  <Grid className={classes.heroHeading} container direction={'row'} justify={'space-around'}>
                    <div>P</div>
                    <div>r</div>
                    <div>i</div>
                    <div>m</div>
                    <div>a</div>
                    <div>l</div>
                    <div>&nbsp;</div>
                    <div>D</div>
                    <div>a</div>
                    <div>r</div>
                    <div>k</div>
                    <div>n</div>
                    <div>e</div>
                    <div>s</div>
                    <div>s</div>
                  </Grid>
                  <Typography className={classes.heroText}>
                    Pull up a log and warm yourself by the fire. Let me tell ye of a world of fantastic
                    magic. A world in a struggle between darkness and light. Aye, a world of grand adventure and wonderful
                    tales of deeds and quests beyond your imagination. I tell ye now of Primal Darkness. A world you just
                    may come to call home...
                  </Typography>
                  <Typography className={classes.heroText}>
                    <Link href={'https://grapevine.haus/games/PD/play'} className={classes.heroLink}>mud.primaldarkness.com:5000</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={8}>
                  <div className={classes.heroBackground}>&nbsp;</div>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <Grid container direction={'row'} justify={'space-around'} alignContent={'flex-start'}>
            <Grid item sm={12} md={8} lg={6} className={classes.card}>
              <Typography className={classes.cardHeading}>WELCOME HOME ADVENTURER</Typography>
              <Typography>
                Our realm is in dire need of you good traveler. Whether ye be good or evil, vile or
                heroic, ye are welcome here! Our realm is growing, new discoveries await the bold and adventurous
                sort. Many an adventurer has traveled this realm and I invite you to join us in creating the culture
                and history of this world!
              </Typography>
              <Typography> Whether you prefer might or magic, leading the charge or lurking in the shadows,
                Primal Darkness boasts twenty three start races, three quest races, five start classes and twenty one
                subclasses to suit your adventuring needs, and a custom remort system allowing you to save all your
                hard work while trying out new races and/or classes. The world has zoned player killing (pk) supported
                by a justice system, player built and ran guilds, a fully sail-able ocean with customizable ships and
                ship battle system, a fully flyable sky with mapping system, mud-wide auction line, a coliseum in
                which to prove your battle prowess, and much more.
              </Typography>
              <Typography>
                Primal Darkness supports over thirty areas to explore with more being rumored at even
                now. PD also has an active building community dedicated to helping the mud expand and grow. Active
                staff and helpful players help make PD the unique experience it has become. We hope you will join us
                in the game world by logging in and playing at <Link to="/play">mud.primaldarkness.com:5000</Link> for
                your next great adventure.
              </Typography>
            </Grid>
            <Grid item sm={12} md={3} lg={2} className={classes.card}>
              <Typography className={classes.cardHeading}>VOTE FOR US</Typography>
              <ul>
                <li>
                  <Link href="https://www.mudconnect.com/cgi-bin/search.cgi?mode=mud_listing&mud=Primal+Darkness" target="_blank">
                    The Mud Connector
                  </Link>
                </li>
                <li><Link href="http://www.topmudsites.com/vote-pdstormy.html" target="_blank">Top Mud Sites</Link></li>
              </ul>
            </Grid>

            <Grid item sm={12} md={12} lg={3} className={classes.card}>
              <Typography className={classes.cardHeading}>PD WORLD MAP</Typography>
              <Grid container direction={'row'} justify={'center'}>
                <Link href="/img/map.png" target="_blank"><img src="/img/map.png" border="0" alt="World Map" className={classes.map} /></Link>
              </Grid>
            </Grid>

            {/*<Grid item sm={12} md={12} lg={6} className={classes.card}>*/}
            {/*  <Typography className={classes.cardHeading}>WHOS ONLINE</Typography>*/}
            {/*  <WhosOnline online={this.props.online} />*/}
            {/*</Grid>*/}

            <Grid item sm={12} md={5} lg={5} className={classes.card}>
              <Typography className={classes.cardHeading}>JOIN US ON DISCORD</Typography>
              <p align="center">
              <iframe src="https://discordapp.com/widget?id=517538747030437898&theme=dark" width="350" height="500"
                      allowtransparency="true" frameBorder="0" />
              </p>
            </Grid>

            <Grid item sm={12} md={5} lg={5} className={classes.card}>
              <Typography className={classes.cardHeading}>CHECK US OUT ON FACEBOOK</Typography>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'}>
                <Grid item xs={11} sm={10} md={11} lg={9}>
                  <iframe
                      title="Check us out on Facebook"
                      src="//www.facebook.com/plugins/fan.php?id=32330731473&amp;width=402&amp;height=460&amp;connections=7&amp;stream=true&amp;header=false"
                      scrolling="no" frameBorder="0" style={{border: 'none', overflow: 'hidden', width: '95%', height: '460px'}} />
                  </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Footer />
        </Grid>
    )
  }
}

Home = withStyles(style)(Home);

export default connect((state, props) => {
  return {
    online: [
      {
        position: 'admin',
        name: 'whit',
        race: 'archangel',
        subclass: 'cleric',
        faction: 'Lightless',

      }
    ]
  }
})(Home);
