import React from 'react';
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { Link as RouterLink } from 'react-router-dom'

const faq = [
  {
    title: 'How do I play Primal Darkness?',
    content: <div>
      Primal Darkness is a telnet based, multi-user text game. Any telnet application can connect to Primal Darkness, but
      we recommend using clients made specifically for MUDs. Some commonly used clients are:
      <ul>
        <li><Link href={'https://www.mudlet.org/'}>Mudlet</Link> - a cross platform MUD client with LUA scripting.</li>
        <li><Link href={'https://tintin.sourceforge.io/starting.php'}>TinTin++</Link> - a powerful command line based client.</li>
      </ul>
    </div>,
  },
  {
    title: 'What is the first thing I should do here as a new player?',
    content: <div>
      Type &lt;help topic&gt;.  Every command on Primal Darkness has a help file, but
      there are other help files as well.  &lt;help topic&gt; will list those other
        help files for you. Try &lt;help rules&gt; first.
    </div>
  },
  {
    title: 'How do I use armor and weapons?',
    content: <div>
      Try &lt;wield&gt;ing or &lt;wear&gt;ing the item.
    </div>
  },
  {
    title: 'Why can\'t I wield this weapon?',
    content: <div>
      You might have a shield worn on the hand you are trying to wield the weapon
      in. If you still cannot wield the weapon, try &lt;wield [weapon] in [right]
      hand&gt; replace [weapon] with the weapon name, and [right] with the hand you
      want to wield it in. Raknids have first hand, second hand, third hand, and
      fourth hand all other races should have left and right hand. All weapons are
      skill based, if you can't use a weapon after trying the above suggestions,
      try raising the weapon skill of the weapon you are trying to use.
    </div>
  },
  {
    title: 'What do the stats and skills mean?',
    content: <div>
      <Typography variant="body1">
        Stats are numerical representations of your basic physical make-up, which
        is determed by your racial heritage.  These stats are used in different
        relevant aspects of your mud life.  Consitution determines how sturdy you
        are.  Intelligence determines your capacity for using magic.  Dexterity
        determines your nimbleness in combat.   Strength determines how much
        you are able to carry.  Wisdom determines your resistence to offensive magic.
        Charisma factors into your ability to get by normally aggressive monsters
        and in the buying and selling of goods.
      </Typography>
      <Typography variant="body1">
        Skills are determined by what subclass you choose.  They represent how good
        you are at something.  We have weapon skills, magic skills, attack skills,
        and deception skills.  Weapon skills determines your proficiency in weapon
        types.  Magic skills determines your proficiency in types of magic.  Attack
        skills determines your proficiency in your ability to attack and defend.
        Deception skills determines your proficiency in types of deception.
      </Typography>
    </div>
  },
  {
    title: 'Does Primal Darkness have guilds?',
    content: <div>
      Yes, we have guilds on Primal Darkness. Guilds are player run  organizations with exclusive memberships. Some
      guilds offer specialized items, shops and storage. See our <Link component={RouterLink} to={"/guilds"}>guild list</Link> for a full
      list of guilds.
    </div>
  },
  {
    title: 'How do I join a guild?',
    content: <div>
      The method will vary from guild to guild.  You should talk to a member
      of the guild you want to join to find out the process for that guild.
      Remember that each guild on Primal Darkness is meant only for a certain
      restricted group of characters, so a guild good for one player is not
      necessarily good for another player.
    </div>
  },
  {
    title: 'Have you seen player X?',
    content: <div>
      There are several ways to see if someone is on Pd. One of the following
      commands should retrieve the information you want. &lt;who&gt; , &lt;finger&gt;,
      &lt;finger [player X]&gt; remember to replace [player X] with the players name.
    </div>
  },
  {
    title: 'Is player killing allowed?',
    content: <div>
      Yes, player killing is allowed but there are limits. There is a justice
      system in play and it can be harsh on criminals. Player killers are
      criminals. Player killing is an evil act and your alignment will reflect
      that. If your alignment is not right for your class or subclass, you could
      loose your spells or abilities. Player killing is not allowed until a player
      reaches hm, this means you can't kill someone and someone can't kill you
      until you reach level 20.
    </div>
  },
];

export default faq;