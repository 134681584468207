import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Link from "@material-ui/core/Link"
import {Link as RouterLink} from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core"
import ClassData from './class_data'
import NotFound from "../../errors/404"

const style = theme => ({
  link: {
    // margin: theme.spacing(2),
  }
});

class SubclassList extends Component {
  render() {
    const { classes, cls } = this.props;

    if (!ClassData[cls]) {
      return <NotFound />
    }

    const classList = Object.keys(ClassData[cls].subclasses).sort().map((k) =>
      <Grid item>
        <Link key={k}
              component={RouterLink}
              to={`/help/classes/${cls}/subclasses/${k}`}
              className={classes.link}>{ClassData[cls].subclasses[k].name}</Link>
      </Grid>)

    return (
      <Grid container direction={'row'} spacing={3}>
        {classList}
      </Grid>
    );
  }
}

SubclassList.propTypes = {};

SubclassList = withStyles(style)(SubclassList);

export default SubclassList;