import React, { Component } from 'react';
import {Link as RouterLink, Route} from "react-router-dom";
import {withStyles} from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"

const style = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    }
  },
})

class LeftNavigation extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Grid className={classes.root}>
          <List>
            <ListItem button component={RouterLink} to="/help/getting-started">
              <ListItemText>Getting Started</ListItemText>
            </ListItem>

            <ListItem button component={RouterLink} to="/help/faq">
              <ListItemText>
                Frequently Asked Questions
              </ListItemText>
            </ListItem>

            <Divider />

            <ListItem button component={RouterLink} to="/help/races">
              <ListItemText>
                Character Races
              </ListItemText>
            </ListItem>

            <ListItem button component={RouterLink} to="/help/classes">
              <ListItemText>
                Character Classes
              </ListItemText>
            </ListItem>

            <ListItem button component={RouterLink} to="/help/quests">
              <ListItemText>
                Quests
              </ListItemText>
            </ListItem>

            <Divider />

            <ListItem button component={RouterLink} to="/guilds">
              <ListItemText>
                Guilds
              </ListItemText>
            </ListItem>

            <Divider />

            <ListItem button component={RouterLink} to="/about">
              <ListItemText>
                About Us
              </ListItemText>
            </ListItem>

            <ListItem button component={RouterLink} to="/terms-of-service">
              <ListItemText>
                Terms of Service
              </ListItemText>
            </ListItem>

            <ListItem button component={RouterLink} to="/privacy-policy">
              <ListItemText>
                Privacy Policy
              </ListItemText>
            </ListItem>

          </List>
        </Grid>
    );
  }
}

LeftNavigation = withStyles(style)(LeftNavigation);

export default LeftNavigation;
