import React, { Component } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {withStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import {grey} from "@material-ui/core/colors"
import Typography from "@material-ui/core/Typography"

const style = theme => ({
  root: {
    borderBottomRightRadius: 2,
    background: grey[600],
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  header: {
    marginRight: theme.spacing(4)
  },
  links: {
    marginTop: theme.spacing(0.5),
    '& a': {
      marginRight: theme.spacing(4),
      color: '#000000',
      fontWeight: 'bold',
    }
  },
});

class Header extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} direction={'row'}>
        <Typography variant={'h5'} className={classes.header}>
          Primal Darkness
        </Typography>
        <Grid item className={classes.links}>
          <Link component={RouterLink} to="/">Home</Link>
          <Link component={RouterLink} to="/help/getting-started">Getting Started</Link>
          <Link href="https://grapevine.haus/games/PD/play">Play Now</Link>
        </Grid>
      </Grid>
    );
  }
}

Header = withStyles(style)(Header);

export default Header;
