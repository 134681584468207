import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Page from "../page/page"
import Typography from "@material-ui/core/Typography"

class PrivacyPolicy extends Component {
  render() {
    return (
        <Page>
          <Typography variant={'h3'}>Privacy Policy</Typography>
          <Typography variant={'body1'}>We will never sell your data.</Typography>
        </Page>
    );
  }
}

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;