import React from 'react';

const guilds = {
  darksouls: {
    name: 'Darksouls',
    leader: 'Escobar',
    description: <div>
      Darksouls, a guild of individuals from all walks of life. A place
      were both Ronin and Mercenary come to talk about war. A place
      that both Necromancer and Clergy can share a drink. A place where
      hated enemies will join with each other and combine forces for
      destruction.
    </div>
  },
  forsaken: {
    name: 'Forsaken',
    leader: 'Abraxas',
    description: <div>
      The Forsaken Guild is a guild of the masters of battle and the arts of warfare.
      Skilled in both the physical and magical disciplines their name invokes fear
      into all who would oppose them. The Forsaken wander the lands in search of
      challenge and treasure. Members are easily distinguished by their sash and the
      coldness in their eyes and heart. Entry to this Guild is only able through
      invite.
    </div>
  },
  knights: {
    name: 'Knights of Valor',
    leader: 'Yun',
    description: <div>
      The Knights of Valor travel the lands in search of peace, righting the wrongs
      of evil and helping all whom are in need.  Unable to kill another brother or
      sister, the Knights of Valor's nobility is known throughout the lands.
      Seek peace and they shall find you.
      Know respect and they will shelter you.
      Seek valor and we will be as one.
    </div>
  },
  unholy: {
    name: 'Unholy',
    leader: 'Enska',
    description: <div>
      The unholy are the sworn followers of Asmodeus.  Though he has
      mysteriously vanished, they continue his work as best they can.
      The Unholy serve the nether demons loyally and many aspire to
      become one themselves.  The location of their hall is mini-quest
      info as you may be asked to find it as a prerequisit to joining.
      Discipline in the Unholy is strict, any breaking of the established
      rules will result in a level of punishment equal to the crime.
      The Unholy is a heavy RPing guild.  For this reason, please do
      not pester anyone over any issues you have with them unless a
      rule has been broken.
    </div>
  },
};

export default guilds;